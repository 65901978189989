<template>
    <div class="history_table">
        <custom-table
            :filters="filters"
            :headers_items="headerItems"
            :loading="loading || !items"
            :content-type="contentType"
            :items="items"
            :options="tableOptions"
            @getData="getList($event)"
            @selectAllCheckbox="selectAllCheckbox($event)"
        />
    </div>
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { headerItems } from '@/helpers/constants/history/content-table-header-items'
import { Filters } from '@/helpers/constants/history/filters'

import {
    ACTION_GET_ACTIVITY_LOG_BY_ID,
    ACTION_GET_USERS
} from '@/store/modules/activity-log/action-types'
import { createNamespacedHelpers, mapState } from 'vuex'
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapActivityLogActions
} = createNamespacedHelpers('activityLog')

export default {
    name: 'Index',
    mixins: [
        contentBaseMixin
    ],
    data() {
        return {
            tableOptions: {
                filters: false,
                content: true,
                toolbar: false,
                hiddenModals: false
            },
            filters: {}
        }
    },
    computed: {
        ...mapState('activityLog', ['activityLogFromId']),
        headerItems() {
            return headerItems
        },
        items() {
            return this.activityLogFromId
        },
        page_filters() {
            return Filters
        },
        isHistoryPage() {
            const {
                name
            } = this.$route
            return name === 'edit-post-blog-history' || name === 'edit-post-landing-history' || name === 'edit-post-task-history' || name === 'edit-post-essay-history'
        }
    },
    watch: {
        '$route.query': debounce(function (val) {
            if (!this.isHistoryPage) return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            this.getList(val)
        }, 50)
    },
    activated() {
        this.getList(this.filters)
    },
    methods: {
        ...mapActivityLogActions([
            ACTION_GET_ACTIVITY_LOG_BY_ID,
            ACTION_GET_USERS
        ]),
        getList(filters) {
            let history_type = null
            if (this.contentType === 'blog' || this.contentType === 'landing' || this.contentType === 'essay') {
                history_type = `data_${this.contentType}`
            } else if (this.contentType === 'task') {
                history_type = 'data_content_tasks'
            }

            this[ACTION_GET_ACTIVITY_LOG_BY_ID]({
                ...filters,
                log_name: history_type,
                subject_id: this.$route.params.id
            })
        }
    }
}
</script>

<style lang="scss">
    .history_table{
        width: 100%;
        .sidebar-opened{
            margin-left: 0 !important;
        }
        .content-layout-wrapper{
            margin: 0;
            background: #fff;
            .content{
                padding: 0 !important;
                .custom-table{
                    padding-left: 0;
                }
                .content-table__filters{
                    padding-left: 0;
                }
            }
        }
    }
</style>
