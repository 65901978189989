export const headerItems = [
    {
        sort_by: 'index'
    },
    {
        classes: 'custom-table__row-updated-at',
        styles: 'justify-content: center',
        sort_by: 'description',
        title: 'Action'
    },
    {
        classes: 'custom-table__row-updated_at',
        styles: 'justify-content: center',
        sort_by: 'properties',
        title: 'Log',
        hide_sort: true
    },
    {
        classes: 'custom-table__row-updated-by',
        styles: 'justify-content: center',
        sort_by: 'user',
        title: 'By'
    },
    {
        classes: 'custom-table__row-updated_by',
        styles: 'display: flex; justify-content: flex-start; width:180px; text-align: left',
        sort_by: 'created_at',
        date_info_type: 'large-info',
        title: 'Created'
    }
]
